#app {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

#ui {
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  pointer-events: none;
}

#header {
  height: 50px;
  background: #ffffff;
  border-bottom: 1px solid #e1e1e1;
  pointer-events: all;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0 16px;
}

#footer {
  background: #ffffff;
  border-top: 1px solid #e1e1e1;
  pointer-events: all;
}

#content {
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

#toolbar {
  width: 50px;
  background: #ffffff;
  border-right: 1px solid #e1e1e1;
  pointer-events: all;
}

#toolbar-pane {
  width: 300px;
  height: calc(100vh - 85px);
  background: #ffffff;
  border-right: 1px solid #e1e1e1;
  pointer-events: all;
  overflow-y: auto;
  padding: 16px;
}

#toolbar-pane * {
  pointer-events: all;
}

#toolbar-pane .mantine-Grid-root .mantine-Col-root {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #fafafa;
}

#toolbar-pane .mantine-Grid-root .mantine-Col-root img {
  transform: scale(1);
  transition: all 0.3s ease;
}

#toolbar-pane .mantine-Grid-root .mantine-Col-root:hover img {
  transform: scale(1.05);
}

.toolbar-expand-button {
  pointer-events: all;

  height: 60px;
}

.toolbar-expand-button svg {
}

#inspectbar {
  width: 300px;
  background: #ffffff;
  border-left: 1px solid #e1e1e1;
  pointer-events: all;
}

#hole {
  flex: 1;
  pointer-events: none;
}

#stage {
  flex: 1;
  background-color: #ebebeb;
}

.toolbar-thumb {
}

.toolbar-thumb img {
}

.toolbar-scroll-area {
}
